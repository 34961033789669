<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-0 text-primary">CSV Importer</h4>
            <hr />
            <div class="row">
              <div class="col">
                <p class="flex alert alert-primary">
                  <i class="far fa-info-circle me-2 py-1"></i>
                  <span>
                    Select your CSV formatted contact file. If the file has headers in its first row, select "File has headers".
                    <br/><br/>
                    If the clients are not fully onboarded yet, select "Import as contacts" to tag them as contacts.
                  </span>
                </p>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="hasHeaders"
                    id="defaultCheck1"
                  />

                  <label class="form-check-label" for="defaultCheck1">File has headers</label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="hasContacts"
                    id="importTypeCheck"
                  />

                  <label class="form-check-label" for="importTypeCheck">Import as contacts</label>
                </div>
                <div class="form-group">
                  <input
                    ref="csv"
                    type="file"
                    name="csv"
                    class="form-control-file"
                    @change="getSample"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col" v-if="sample">
        <div class="card">
          <div class="card-header">
            <h4 class="mb-0 text-primary">Preview & Map Fields</h4>
          </div>
          <div class="card-loading">
            <busy>Loading</busy>
          </div>
          <div class="card-body">
            <p class="alert alert-primary">
              <i class="far fa-info-circle me-1"></i>
              Map your CSV fields to your Swandoola clinic. Choose the appropriate headers that match the data point for each column.
            </p>
            <table class="table">
              <thead v-if="hasHeaders">
                <tr>
                  <th
                    v-for="(sh, index) in sampleHeaderData"
                    :class="{ selectedcol: sh.colSelected, alreadyselectederror: sh.fieldAlreadySelectedError}"
                    :key="`th-${index}-${sh.field}`"
                  >
                    <div class>
                      <select @change="optionIsSelected(sh, $event)">
                        <option
                          v-for="header in fieldsToMap"
                          :selected="header.key == sh.field"
                          :key="`select-option-${header.key}`"
                          :value="header.key"
                        >{{ header.label }}</option>
                      </select>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in sample" :key="row.key">
                  <td v-for="col in row" :key="col.key">{{ col }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-if="fileChosen">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-0 text-primary">Import Mapped Fields</h4>
            <hr />
            <div class="row">
              <div class="col">
                <p>
                  Detected <b>{{this.numRows}}</b> <span v-if="this.hasContacts">contacts</span><span v-else>clients</span> to import.
                </p>
                <p class="alert alert-danger" v-if="overBillingThreshold">
                  You have <b>{{this.clientLimit - this.clinicClients}}</b> clients remaining in your package. This import would exceed that threshold. Please <a href="/billing/upgrade">upgrade your package</a> to add more.
                </p>
                <div class="form-group">
                  <input type="submit"
                         class="btn btn-primary"
                         @click.prevent="submit"
                         :disabled="overBillingThreshold"
                         value="Import CSV"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col alert alert-success" v-if="showMessage" rol="alert">
        <h4 class="alert-heading">Success!</h4>
        <p>Created: {{ created }}</p>
        <p>Updated: {{ updated }}</p>
      </div>
      <div class="col alert alert-danger" v-if="showError" rol="alert">
        <h4 class="alert-heading">{{ showError }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Papa from "papaparse";

export default {
  props: [""],
  data() {
    return {
      clinicClients: [],
      selectedColumn: [],
      fieldCopy: {},
      showMessage: false,
      showError: null,
      created: 0,
      updated: 0,
      fieldsToMap: {},
      sampleHeader: [],
      sampleHeaderData: {},
      headers: [],
      map: {},
      hasHeaders: true,
      hasContacts: false,
      csv: null,
      sample: null,
      url: false,
      googleImportFields: [
        "first_name",
        "last_name",
        "email",
        "dob",
        "country",
        "town",
        "city",
        "address_1",
        "address_2",
        "post_code"
      ],
      fileChosen: false,
    };
  },
  created() {
    this.fetchClinicClientsCount();
    this.getImportFieldsAndLabels();
  },
  computed: {
    numRows() {
      if (this.csv) {
        return (this.hasHeaders ? this.csv.length - 1 : this.csv.length);
      } else {
        return null;
      }
    },
    clientLimit() {
      return this.user.clinic.billing_subscription.billing_product.client_limit;
    },
    overBillingThreshold() {
      if (this.clientLimit < 1){
        return false;
      } else {
        return this.clientLimit < this.numRows + this.clinicClients;
      }
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  methods: {
    fetchClinicClientsCount() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/clients/api/count").then(({ data }) => {
        this.clinicClients = data;
      });
    },
    optionIsSelected(header, e) {
      header.fieldSelection = e.target.value;
      header.colSelected = true;

      let foundHeader = false;

      this.sampleHeaderData.forEach(hd => {
        hd.fieldAlreadySelectedError = false;
        this.showError = null;
      });

      this.sampleHeaderData.forEach(hd => {
        if (hd.fieldSelection == header.fieldSelection && header !== hd) {
          hd.fieldAlreadySelectedError = header.fieldAlreadySelectedError = true;
        }
      });

      this.sampleHeaderData.forEach(hd => {
        if (hd.fieldSelection == "not_selected") {
          hd.colSelected = false;
          hd.fieldAlreadySelectedError = false;
        }
      });
    },

    async submit() {
      const _this = this;
      let duplicates = {};
      let toRemove = [];
      let hasEmail = false;
      let hasFirstName = false;
      let hasLastName = false;

      this.sampleHeaderData.forEach((header, index) => {
        if (header.fieldAlreadySelectedError) {
          this.showError = "Two or more columns with the same header";
        }

        if (header.fieldSelection == "not_selected") {
          toRemove.push(index);
        }

        if (header.fieldSelection == "email") {
          hasEmail = true;
        }

        if (header.fieldSelection == "first_name") {
          hasFirstName = true;
        }

        if (header.fieldSelection == "last_name") {
          hasLastName = true;
        }
      });

      if (this.showError) {
        return;
      }

      if (!hasEmail) {
        alert("Must have an email column");
        return;
      }
      if (!hasFirstName) {
        alert("Must have a First Name column");
        return;
      }
      if (!hasLastName) {
        alert("Must have a Last Name column");
        return;
      }

      this.csv = this.csv
        .filter(row => row.some(cell => cell))
        .map(row => row.filter((col, index) => !toRemove.includes(index)));

      const newHeaders = this.sampleHeaderData
        .filter((col, index) => !toRemove.includes(index))
        .map(headerData => headerData.fieldSelection);

      if (this.hasHeaders) {
        this.csv[0] = newHeaders;
      } else {
        this.csv.unshift(newHeaders);
      }

      try {
        if (this.hasHeaders) {
          $('.card-loading').css('display', 'flex');
          let response = await this.$axios.post(
            process.env.VUE_APP_API_URL + "/clients/import/csv/upload",
            {
              csv: this.csv,
              hasHeaders: this.hasHeaders,
              hasContacts: this.hasContacts
            }
          );

          if (response.status == 200) {
            this.resetData();

            this.updated = response.data.updated;
            this.created = response.data.created;
            this.showMessage = true;

            $('.card-loading').css('display', 'none');

            setTimeout(() => {
              this.resetData();
            }, 5000);

          }
        }
      } catch (e) {
        console.error("e :", e);
        $('.card-loading').css('display', 'none');
      }
      $('.card-loading').css('display', 'none');
    },

    async getImportFieldsAndLabels() {
      try {
        let response = await this.$axios.get(process.env.VUE_APP_API_URL + "/clients/import/fields");
        this.fieldsToMap = response.data.data;

        const _this = this;
        this.fieldsCopy = this.fieldsToMap;

        this.fieldsToMap = Object.keys(this.fieldsToMap).map(key => {
          let label = this.fieldsToMap[key];
          _this.selectedColumn.push(key);

          return {
            key: key,
            label: label,
            selected: false
          };
        });

        this.fieldsToMap.unshift({
          key: "not_selected",
          label: "SELECT COLUMN",
          selected: false
        });

        /*if (typeof laravel.contacts !== "undefined") {
          this.processGoogleContacts(laravel.contacts);
        }*/
      } catch (e) {
        console.error("There was a problem importing fields and labels :", e);
      }
    },

    resetData() {
      this.$refs.csv.files = null;
      this.sampleHeader = [];
      this.headers = [];
      this.csv = null;
      this.sample = null;
      this.selectedColumn = [];
      this.fieldCopy = {};
      this.showMessage = false;
      this.created = 0;
      this.updated = 0;
    },

    processGoogleContacts(contacts) {
      let toProcess = {
        fields: this.googleImportFields
      };

      let data = contacts.map(info => {
        let firstName =
          typeof info.names !== "undefined" &&
          typeof info.names.first_names !== "undefined" &&
          info.names.first_names.length != 0
            ? info.names.first_names[0]
            : null;

        let lastName =
          typeof info.names !== "undefined" &&
          typeof info.names.last_names != "undefined" &&
          info.names.last_names.length != 0
            ? info.names.last_names[0]
            : null;

        let email =
          typeof info.emails !== "undefined" && info.emails.length != 0
            ? info.emails[0]
            : null;

        let dob =
          typeof info.birthdays !== "undefined" && info.birthdays.length != 0
            ? info.birthdays[0]
            : null;

        let address1 =
          typeof info.addresses !== "undefined" &&
          info.addresses.length != 0 &&
          info.addresses[0].address_1 != "undefined"
            ? info.addresses[0].address_1
            : null;

        let address2 =
          typeof info.addresses !== "undefined" &&
          info.addresses.length != 0 &&
          info.addresses[0].address_2 != "undefined"
            ? info.addresses[0].address_2
            : null;

        let country =
          typeof info.addresses !== "undefined" &&
          info.addresses.length != 0 &&
          info.addresses[0].country != "undefined"
            ? info.addresses[0].country
            : null;

        let town =
          typeof info.addresses !== "undefined" &&
          info.addresses.length != 0 &&
          info.addresses[0].town != "undefined"
            ? info.addresses[0].town
            : null;

        let city =
          typeof info.addresses !== "undefined" &&
          info.addresses.length != 0 &&
          info.addresses[0].city != "undefined"
            ? info.addresses[0].city
            : null;

        let postCode =
          typeof info.addresses !== "undefined" &&
          info.addresses.length != 0 &&
          info.addresses[0].post_code != "undefined"
            ? info.addresses[0].post_code
            : null;

        let row = [
          firstName,
          lastName,
          email,
          dob,
          country,
          town,
          city,
          address1,
          address2,
          postCode
        ];

        return row;
      });

      toProcess.data = data;
      let csv = Papa.unparse(toProcess);

      this.sample = _.get(Papa.parse(csv, { preview: 5 }), "data");
      this.sampleHeader = this.sample[0];

      let keys = this.fieldsToMap.map(fieldSet => fieldSet.key);

      this.sampleHeaderData = this.sampleHeader.map(header => {
        let containsHeader = false;

        return {
          fieldSelection: keys.includes(header) ? header : "not_selected",
          fieldAlreadySelectedError: false,
          field: header,
          colSelected: keys.includes(header)
        };
      });

      this.sample = this.sample.slice(1);
      this.csv = _.get(Papa.parse(csv), "data");
    },
    // Preview first 4 rows from data set (not including header)
    getSample() {
      this.fileChosen = true;
      const _this = this;
      this.readFile(output => {
        // Gets sample rows
        _this.sample = _.get(Papa.parse(output, { preview: 5 }), "data");

        _this.sample = _this.sample.filter(function (el) {
          return el.length > 1;
        });

        // Grab header row
        _this.sampleHeader = _this.sample[0];
        // keys are all available csv header column names
        let keys = this.fieldsToMap.map(fieldSet => fieldSet.key);

        _this.sampleHeaderData = _this.sampleHeader.map(header => {
          return {
            // if given header value is in allowed csv headers set it as selection
            fieldSelection: keys.includes(header) ? header : "not_selected",
            fieldAlreadySelectedError: false,
            field: header, //TODO: Not sure if this causes problems.
            colSelected: keys.includes(header) // automatically select if given header is also in our list of headers
          };
        });

        // this.sample now includes the data less the header row
        _this.sample = _this.sample.slice(1);
        // Set the actual csv data. What will be uploaded
        _this.csv = _.get(Papa.parse(output), "data");
      });
    },
    readFile(callback) {
      let file = this.$refs.csv.files[0];
      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function(evt) {
          callback(evt.target.result);
        };
        reader.onerror = function() {};
      }
    }
  }
};
</script>

<style scoped lang="scss">
th {
  white-space: nowrap;
}

th input {
  margin-left: 10px;
}

.selectedcol {
  background-color: var(--primary);
}

.alreadyselectederror {
  background-color: var(--danger);
}

.card {
  overflow: auto;
}
</style>
